import React from "react";
import "./assets/stylesheets/style.css";

function App() {
  return (
    <React.Fragment>
      <p>go back to <a href="https:/jeith.com/">https://jeith.neocities.org/</a>!!</p>
    </React.Fragment>
  );
}

export default App;
